export const specialDay = {
    user: "member",
    profileImageUrl: "profileImageUrl",
    name: "fullName",
    dateType: "dateType",
    date: "date",
};

export const specialDaysTypes = {
    "ANNIVERSARY": "Anniversary",
    "GRADUATION": "Graduation",
    "OTHERS": "Others",
    "RETIREMENT":"Retirement"
};
export const someSpecialDaysTypes={
    "BIRTHDAY": "Birthday",
    "CLIENT_ANNIVERSARY": "Client Anniversary",
}

export const editorKeys = {
    bold: "bold",
    italic: "italic",
    underline: "underline",
    at: "at",
    link: "link",
    toggle: "toggle",
};

export const activitystatusClientKey = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
    CONVERTED: "CONVERTED",
    NEEDS_REVIEW: "NEEDS_REVIEW",
    REVIEW_DONE: "REVIEW_DONE",
    READY: "READY",
    ADVANCE_REVIEW: "ADVANCE_REVIEW",
};

export const activityStatusClientLabel = {
    ACTIVE: "Active",
    INACTIVE: "Inactive",
    CONVERTED: "Converted",
    NEEDS_REVIEW: "Needs Review",
    REVIEW_DONE: "Review Done",
    READY: "Ready",
    ADVANCE_REVIEW: "Advance Review",
};

export const addMemberType = {
    PERSON: "PERSON",
    PET: "PET",
    TRUST: "TRUST",
    BUSINESS: "BUSINESS",
};

export const addLegacyType = {
    PERSON: "MEMBER",
    TRUST: "TRUST",
};

export const notesTypes = {
    prospects: "PROSPECT",
    highlights: "HIGHLIGHTS",
    workflow: "WORKFLOW",
    managedAccounts: "MANAGED_ACCOUNTS",
    dashboard: "DASHBOARD",
    suitability: "SUITABILITY",
};

export const riskManagementPeriod = {
    "1 Year": "1_YEARS",
    "2 Years": "2_YEARS",
    "3 Years": "3_YEARS",
    "4 Years": "4_YEARS",
    "5 Years": "5_YEARS",
    "6 Years": "6_YEARS",
    "7 Years": "7_YEARS",
    "8 Years": "8_YEARS",
    "9 Years": "9_YEARS",
    "10 Years": "10_YEARS",
    "15 Years": "15_YEARS",
    "20 Years": "20_YEARS",
    "25 Years": "25_YEARS",
    "30 Years": "30_YEARS",
    "40 Years": "40_YEARS",
    "To Age 65": "TO_AGE_65",
    "To Age 67": "TO_AGE_67",
    "To Age 100": "TO_AGE_100",
    "To Age 120": "TO_AGE_120",
};

export const riskManagementFrequency = {
    Monthly: "MONTHLY",
    Quarterly: "QUARTERLY",
    "Semi-Annually": "SEMI_ANNUAL",
    Annually: "ANNUAL",
    "One-Time": "ONE_TIME",
    "Paid-Up": "PAID_UP",
};

export const riskManagementPolicyType = {
    "Life Insurance": "LIFE_INSURANCE",
    "Disability Insurance": "DISABILITY_INSURANCE",
    "Long-Term Care Insurance": "LONG_TERM_CARE_INSURANCE",
    "Supplemental Insurance": "SUPPLEMENTAL_INSURANCE",
    "Other Insurance": "OTHER_INSURANCE",
    "Guaranteed Annuity": "GUARANTEED_ANNUITY",
    "Indexed Annuity": "INDEXED_ANNUITY",
    "Other Annuity": "OTHER_ANNUITY",
};

export const memberSubType = {
    Primary: "PRIMARY",
    Secondary: "SECONDARY",
    Spouse: "SPOUSE",
    Partner: "PARTNER",
    ["Child of Primary"]: "CHILD_OF_PRIMARY",
    ["Child of Secondary"]: "CHILD_OF_SECONDARY",
    ["Child of Both"]: "CHILD_OF_BOTH",
    None: null,
};

export const clientNoteTaskType = {
    "FOLLOW UP": {
        label: "Follow Up",
        value: "FOLLOW UP",
    },
    GENERAL: {
        label: "General",
        value: "GENERAL",
    },
    PERSONAL: {
        label: "Personal",
        value: "PERSONAL",
    },
    PHONE: {
        label: "Phone",
        value: "PHONE",
    },
    EMAIL: {
        label: "Email",
        value: "EMAIL",
    },
    MISC: {
        label: "Misc",
        value: "MISC",
    },
};

export const priorityValue = {
    LOW: 10,
    MEDIUM: 20,
    HIGH: 30,
};

export const sourceEnum = {
    seminar: "SEMINAR",
    internet: "INTERNET",
    networking: "NETWORKING",
    tvShow: "TV_SHOW",
    referral: "REFERRAL",
    centerOfInfluence: "CENTER_OF_INFLUENCE",
    other: "OTHER",
    socialMedia: "SOCIAL_MEDIA",
    radioShow: "RADIO_SHOW",
    tvAd: "TV_AD",
    radioAd: "RADIO_AD",
    personalObservation: "PERSONAL_OBSERVATION",
    coldCall: "COLD_CALL",
    practiceAcquisition: "PRACTICE_ACQUISITION",
};

export const clientReviewEnum = {
    NEEDS_REVIEW: {
        label: "NEEDS REVIEW",
        value: "NEEDS_REVIEW",
    },
    READY: {
        label: "Ready",
        value: "READY",
    },
};

export const clientBillingStatusOptions = [
    {
        title: "Ready To Bill",
        value: "READY",
    },
    {
        title: "Requires Attention",
        value: "REQUIRES_ATTENTION",
    },
];

export const clientHouseStatusOptions = [
    {
        title: "Active",
        value: "ACTIVE",
    },
    {
        title: "Inactive",
        value: "INACTIVE",
    },
];

export const advClientCategoryOptions = [
    {
        title: "Corporations Other Business",
        value: "CORPORATIONS_OTHER_BUSINESSES",
    },
    {
        title: "Individual",
        value: "INDIVIDUAL",
    },
    {
        title: "Hight NetWorth Individual",
        value: "HIGH_NETWORTH_INDIVIDUAL",
    },
    {
        title: "Unassigned",
        value: "UNASSIGNED",
    },
    {
        title: "Pension Profilt Sharing Plan",
        value: "PENSION_PROFIT_SHARING_PLAN",
    },
    {
        title: "Other",
        value: "OTHER",
    },
];

export const clientPayMethodOptions = [
    {
        title: "Unassigned",
        value: "UNASSIGNED",
    },
    {
        title: "ACH",
        value: "ACH",
    },
    {
        title: "Indirect",
        value: "INDIRECT",
    },
];

export const clientFreFrequency = [
    {
        title: "Monthly",
        value: "MONTHLY",
    },
    {
        title: "Quarterly",
        value: "QUARTERLY",
    },
    // {
    //     title: "Semi Annually",
    //     value: "SEMI_ANNUAL",
    // },
    // {
    //     title: "Annually",
    //     value: "ANNUAL",
    // },
];

export const clientACHAccount = [
    {
        title: "Checking",
        value: "CHECKING",
    },
    {
        title: "Savings",
        value: "SAVINGS",
    },
];

export const payStyleTypesOptions = [
    {
        id: 1,
        title: "Advance Bill",
        value: "ADVANCE_BILL",
    },
];

export const payStyleTypes = {
    advanceBill: "ADVANCE_BILL",
};
export const payoutBlocksType = {
    // "Centric RIA": "Centric RIA",
    "Wealth Advisor 1": "Wealth Advisor 1",
    "Wealth Advisor 2": "Wealth Advisor 2",
    "Solicitor": "Solicitor",
    "Business Developer": "Business Developer",
    "Financial Planner": "Wealth Advisor 3"
};

export const payoutBlocksPaymentType = {
    "# Of Payments": "Number_Of_Payments",
    Forever: "Forever",
};

export const productTypeOptions = {
    "ADVISORY": "ADVISORY",
    "RISK": "RISK"
};

export const productOptions = {
    "Life Insurance": "LIFE_INSURANCE",
    "Assurity": "ASSURITY",
    "product3": "product3"
};

export const clientRiskManagementPolicyType = [
    {
        title: "Life Insurance",
        value: "life_insurance",
        //"icon":"circle.svg",
        imageStyle: "h-2 w-2",
        type: "label",
        submenu: [
            {
                title: "Term",
                value: "LIFE_INSURANCE_TERM",
                //"icon":"circle-ring.svg",
                submenu: [],
                imageStyle: "h-2 w-2",
            },
            {
                title: "IUL",
                value: "LIFE_INSURANCE_IUL",
                //"icon":"circle-ring.svg",
                submenu: [],
                imageStyle: "h-2 w-2",
            },
            {
                title: "UL",
                value: "LIFE_INSURANCE_UL",
                //"icon":"circle-ring.svg",
                submenu: [],
                imageStyle: "h-2 w-2",
            },
            {
                title: "GUL",
                value: "LIFE_INSURANCE_GUL",
                //"icon":"circle-ring.svg",
                submenu: [],
                imageStyle: "h-2 w-2",
            },
            {
                title: "Whole",
                value: "LIFE_INSURANCE_WHOLE",
                //"icon":"circle-ring.svg",
                submenu: [],
                imageStyle: "h-2 w-2",
            },
        ],
    },
    {
        title: "Disability Insurance",
        value: "DISABILITY_INSURANCE",
        //"icon":"circle.svg",
        submenu: [],
        imageStyle: "h-2 w-2",
    },
    {
        title: "Long-Term Care Insurance",
        value: "LONG_TERM_CARE_INSURANCE",
        //"icon":"circle.svg",
        submenu: [],
        imageStyle: "h-2 w-2",
    },
    {
        title: "Supplemental Insurance",
        value: "SUPPLEMENTAL_INSURANCE",
        //"icon":"circle.svg",
        submenu: [],
        imageStyle: "h-2 w-2",
    },
    {
        title: "Other Insurance",
        value: "OTHER_INSURANCE",
        //"icon":"circle.svg",
        submenu: [],
        imageStyle: "h-2 w-2",
    },
    {
        title: "Annuity",
        value: "2",
        //"icon":"circle.svg",
        imageStyle: "h-2 w-2",
        type: "label",
        submenu: [
            {
                title: "Guaranteed Annuity",
                value: "GUARANTEED_ANNUITY",
                submenu: [],
                //"icon":"circle-ring.svg",
                imageStyle: "h-2 w-2",
            },
            {
                title: "Indexed Annuity",
                value: "INDEXED_ANNUITY",
                submenu: [],
                //"icon":"circle-ring.svg",
                imageStyle: "h-2 w-2",
            },
            {
                title: "Other Annuity",
                value: "OTHER_ANNUITY",
                submenu: [],
                //"icon":"circle-ring.svg",
                imageStyle: "h-2 w-2",
            },
        ],
    },
];

export const feeStructurePayByType = {
    fixed: "FIXED",
    percentage: "PERCENTAGE",
}

export const feeGeneralType = {
    linear: 'LINEAR',
    tiered: 'TIERED'
}

export const feeGeneralBasisType = {
    household: 'HOUSEHOLD',
    account: 'ACCOUNT'
}

export const billTypes = {
    'Financial Planning': 'FINANCIAL_PLANNING',
    'Investment Management': 'INVESTMENT_MANAGEMENT'
}

export const forecastLiveTypes = {
    'Forecast Bill': 'FORECAST_BILL',
    'Live Bill': 'LIVE_BILL'
}

export const runForTypes = {
    "All Units": 'ALL_UNITS',
    'Single Unit': 'SINGLE_UNIT',
    'Single Account': 'SINGLE_ACCOUNT',
    "WA": "WEALTH_ADVISOR",
}

export const assetClass = {
    "Large Cap US Equity": "Large Cap US Equity"
}

export const productType = {
    "Stock/ETF": "Stock/ETF"
}

export const avdAssetCategory = {
    "Equity(E)": "Equity(E)"
}

export const payMethodTypesOptions = [
    {
        id: 1,
        title: "Direct",
        value: "DIRECT",
    },
    {
        id: 2,
        title: "Indirect",
        value: "INDIRECT",
    },
];

export const feeScheduleOptions = [
    {
        id: 1,
        title: "Asset Management",
        value: "ASSET_MANAGEMENT",
    },
]

export const valuationMethodOptions = [
    {
        id: 1,
        title: "Period End Value",
        value: "PERIOD_END_VALUE",
    },
];

export const valuationMethod = {
    periodEndValue: "PERIOD_END_VALUE",
};

export const viewableByClientOptions = {
    "Model": "MODEL",
    "Model and Performance": "MODEL_AND_PERFORMANCE",
    "Hidden": "HIDDEN"
};

export const excludeAssetsAssignmentType = [
    {
        id: 1,
        title: "Asset Category",
        value: "ASSET_CATEGORY",
    },
    {
        id: 2,
        title: 'CUSIP',
        value: 'CUSIP'
    }
];

export const productFrequency = [
    {
        title: "Monthly",
        value: "MONTHLY",
    },
    {
        title: "Quarterly",
        value: "QUARTERLY",
    },
    {
        title: "Semi Annually",
        value: "SEMI_ANNUAL",
    },
    {
        title: "Annually",
        value: "ANNUAL",
    },
];

export const phoneNumberTypeOptions = [
    {
        title: "Mobile",
        value: "MOBILE",
    },
    {
        title: "Work",
        value: "WORK",
    },
    {
        title: "Home",
        value: "HOME",
    },
    {
        title: "Other",
        value: "OTHER",
    },
]

export const emailTypeOptions = [
    {
        title: "Work",
        value: "WORK",
    },
    {
        title: "Personal",
        value: "PERSONAL",
    },
    {
        title: "Home",
        value: "HOME",
    },
    {
        title: "Other",
        value: "OTHER",
    },
]

export const addressTypeOptions = [
    {
        title: "Home",
        value: "HOME",
    },
    {
        title: "Work",
        value: "WORK",
    },
    {
        title: "Vacation",
        value: "VACATION",
    },
    {
        title: "Other",
        value: "OTHER",
    },
]

export const activitiStatusOptions = [
    {
        key: 1,
        label: "Active",
        value: "ACTIVE",
    },
    {
        key: 2,
        label: "Inactive",
        value: "INACTIVE",
    },
    {
        key: 3,
        label: "Converted",
        value: "CONVERTED",
    },
];

export const leadStatusOption = [
    {
        key: "hot",
        label: "Hot",
        value: "HOT",
    },
    {
        key: "warm",
        label: "Warm",
        value: "WARM",
    },
    {
        key: "cold",
        label: "Cold",
        value: "COLD",
    },
];

export const activityTypeOptions = [
    {
        label: "Activity Type",
        value: "",
        key: 1,
        isDisabled: true,
        isSelected: true,
    },
    {
        label: "In-person Meeting",
        value: "IN_PERSON_MEETING",
        key: 2,
    },
    {
        label: "Phone",
        value: "PHONE_CALL",
        key: 3,
    },
    {
        label: "Email",
        value: "EMAIL",
        key: "Email",
    },
    {
        label: "Video Meeting",
        value: "VIDEO_MEETING",
        key: 4,
    },
]

export const apiResponseType = {
    IDLE: "",
    FETCHING: "FETCHING",
    SUCCESS: "SUCCESS",
    ERROR: "ERROR",
}

export const annualIncomeRange = {
    "0-25000": "$0 - $25,000",
    "25001-50000": "$25,001 - $50,000",
    "50001-100000": "$50,001 - $100,000",
    "100001-175000": "$100,001 - $175,000",
    "175001-250000": "$175,001 - $250,000",
    "250000+": "Over $250,000",
}

export const estimatedNetWorthRange = {
    "0-50000": "$0 - $50,000",
    "50001-100000": "$50,001 - $100,000",
    "100001-250000": "$100,001 - $250,000",
    "250001-500000": "$250,001 - $500,000",
    "500001-1000000": "$500,001 - $1,000,000",
    "1000000+": "Over $1,000,000",
}

export const liquidityNeedsEnum = {
    NOT_IMPORTANT: "Not Important",
    SOMEWHAT_IMPORTANT: "Somewhat Important",
    IMPORTANT: "Important",
    VERY_IMPORTANT: "Very Important",
}

export const riskToleranceEnum = {
    CONSERVATIVE: "Conservative",
    MODERATE_CONSERVATIVE: "Moderate Conservative",
    MODERATE: "Moderate",
    MODERATE_AGGRESSIVE: "Moderate Aggressive",
    AGGRESSIVE: "Aggressive",
}

export const timeHorizonEnum = {
    "<1": "Under 1 Year",
    "1-2": "1 - 2 Years",
    "3-5": "3 - 5 Years",
    "6-10": "6 - 10 Years",
    "10-20": "10 - 20 Years",
    "20+": "Over 20 Years",
}

export const investmentObjectivesEnum = {
    SAFETY_OF_PRINCIPAL: "Safety Of Principal",
    INCOME: "Income",
    GROWTH: "Growth",
    SPECULATION: "Speculation",
}

export const taxStatusEnum = {
    "0-15": "0 - 15%",
    "16-25": "16% - 25%",
    "25-33": "25% - 33%",
    "34+": "34% or greater",
}

export const investmentObjectivesDescEnum = {
    SAFETY_OF_PRINCIPAL: "Preservation of initial principal, with minimal risk, returns may not keep pace with inflation.",
    INCOME: "Accept some risk to initial principal and tolerate moderate volatility to seek higher returns.",
    GROWTH: "3-5 Years",
    SPECULATION: "6-10 Years",
}
export const connectionsType = {
    INTRODUCED_BY: "introducedBy",
    FAMILY: "family",
    INTRODUCTIONS: "introductions"
}

export const relationType = {
    "BROTHER": "Brother",
    "SISTER": "Sister",
    "PARENT": "Parent",
    "CHILD": "Child",
    "SISTER_IN_LAW": "Sister In-Law",
    "BROTHER_IN_LAW": "Brother In-Law",
    "PARENT_IN_LAW": "Parent In-Law",
    "CHILD_IN_LAW": "Child In-Law",
    "BUSINESS_PARTNER": "Business Partner",
    "COUSIN": "Cousin",
    "UNCLE": "Uncle",
    "NEPHEW": "Nephew",
    "AUNT": "Aunt",
    "NIECE": "Niece",
    "GRANDPARENT": "Grandparent",
    "GRANDCHILD": "Grandchild"
}

export const otherProffTypeOptions = {
    ATTORNEY: "Attorney",
    BANKER: "Banker",
    INSURANCE_ADVISOR: "Insurance Advisor",
    LENDER: "Lender",
    OTHER: "Other",
    TAX_ADVISOR: "Tax Advisor",
};
export const teamMembers = [
    { value: "wealthAdvisor", label: "Wealth Advisor 1" },
    { value: "wealthAdvisor2", label: "Wealth Advisor 2" },
    { value: "businessDeveloper", label: "Business Developer" },
    { value: "solicitor", label: "Solicitor" },
    { value: "financialPlanner", label: "Wealth Advisor 3" },
]
export const confirmationDialogType = {
    NULL: 0,
    SAVE: 1,
    CANCEL: 2
}
export const totalStatisticEnum={
    "managedAccount":'Managed Accounts',
    "annuityAssets":'Annuity Assets',
    "lifeAssets":'Life Assets',
    "lifeDB":'Life DB',
  
  }
  export const averageStatisticEnum = {
    "averageAge": 'Years Old',
    "averageUnitBalance": 'Unit Balance',
    "averageServices": '# of Services',
    "averageCientRating":'Client rating'
  }

  export const tierFilterArr = [
    'all',0, 1, 2, 3, 4, 5
  ].map(item => ({
    label: `${item} star`,
    value: `${item}`
  }))
  export const influencerFilterArr = [
    'all','Gold', 'Silver', 'Bronze','no status'
  ].map(item => ({
    value: `${item}`,
    icon:`${item.toLocaleLowerCase()}_star.svg`
  }))

  export const servieseObj={
        financialLifeManagement: false,
        riskManagement: false,
        assetManagement: false,
        legacyPlanning: false,
        privateFund: false,
        noService:false
      }
      export const globalSeachIcon = {
        "client": 'client',
        "account":"account",
        "unit":"unit"
      }

export const authorizationStateEnum = {
    CHECKING: "checking",
    AUTHORIZED: "authorized",
    UNAUTHORIZED: "unauthorized"
}
