import React, { useEffect, useMemo,useRef } from 'react'
import PropTypes from "prop-types"
import { useState } from 'react';
import cx from "classnames";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faUser } from '@fortawesome/free-solid-svg-icons';
import { useFileUpload } from '../../../hooks/useFileUpload';
import { getInputElement } from '../../../utils/UtilsFunc';
import Image from '../../../commonComponent/Image';
import Field from '../../UI/Field';
import { useFormikContext } from 'formik';
import reject from "../../../assets/img/icons/reject.svg";

function Loader() {
  return (
    <div className="flex justify-center items-center">
      <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-gray-900"></div>
    </div>
  );
}

const ProfileImageComponent = ({
  profileImage,
  value,
  errors = {},
  touched = {},
  onChange,
  onBlur,
  isEdit,
  name,
  subForm,
  wrapperStyles,
}) => {
  const { values, setFieldValue } = useFormikContext();
  const [enableCompanyName, setCompanyName] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);
  var { imageMeta, upload, isLoading, error,setState} = useFileUpload({
    pathToUpload: "fileAttachment",
    validation: function (file) {
      const filename = file.name;
      const ext = filename.substr(filename.lastIndexOf(".") + 1);
      const kb = file.size / 1024;
      const mb = kb / 1024;  // Convert KB to MB
      const mimeTypeAllowed = ["jpg", "png", "jpeg"];
      if (!mimeTypeAllowed.includes(ext?.toLowerCase())) {
        return "File format accepted only .jpg, .jpeg, .png up to 15 MB";
      }
      if (mb > 15) {  // Check for 15 MB limit
        return "File format accepted only .jpg, .jpeg, .png up to 15 MB";
      }

      return true;
    },
  });

  var [initial, setInitial] = useState(null);
  const fileInputRef=useRef(null)
  const refElemet = useMemo(() => {
    return getInputElement({
      name: "info.profileImageUrl",
      value: value.profileImageUrl,
      onChange: onChange,
      onBlur: onBlur,
    });
  }, []);

  // let userImg = cx("relative mb-2 bg-gray-100 cursor-pointer profileImage flex justify-center", {
  //   "w-1/3": profileImage.length > 0,
  // });

  let userImg = cx("relative mb-2 bg-gray-100 cursor-pointer profileImage flex");

  const userOnMouseEnter = () => {
    setMouseOver(true);
  };
  const userOnMouseLeave = () => {
    setMouseOver(false);
  };

  function handleChange(e) {
    upload(e.target.files[0]);
  }

  useEffect(() => {
    if (imageMeta?.attachmentUrl) {
      // value.profileImageUrl = imageMeta?.attachmentUrl;
      setFieldValue(name, imageMeta?.attachmentUrl)
      refElemet.setValue(imageMeta?.attachmentUrl);
      return;
    }
    if (value.profileImageUrl && isEdit) {
      refElemet.setValue(value.profileImageUrl);
      setInitial(value.profileImageUrl);
    }
  }, [imageMeta]);

  useEffect(() => {
    if (values.info?.worksAt) {
      setCompanyName(true);
    }
  }, []);

  function getValueFromName(values, name) {
    if (name?.split(".").length === 1) return values[name];
    var [main, subMain] = name.split(".");
    return values[main] ? values[main][subMain] : "";
  }

  profileImage = imageMeta?.attachmentUrl
    ? imageMeta?.attachmentUrl
    : initial
      ? initial
      : profileImage;

  return (
    <div className={`bg-gray-100 text-center py-8 w-full ${wrapperStyles}`}>
      <div className='flex flex-col items-center'>
        <div
          className={userImg}
          onMouseEnter={userOnMouseEnter}
          onMouseLeave={userOnMouseLeave}
        >
          {mouseOver && (
            <div className="w-28 h-28 rounded-md mx-auto  bg-blue-500 absolute z-1">
              <FontAwesomeIcon
                icon={faCamera}
                size="2x"
                className="text-white absolute left-0 right-0 top-0 bottom-0 m-auto z-2"
              />
            </div>
          )}
          {profileImage?.length ? (
            <div className='relative'>
              <Image
                img={profileImage}
                imgClass="w-28 h-28 rounded-md border-2 border-white  object-cover mx-auto"
              />
              <img
                onClick={() =>{
                  setFieldValue(name, '');
                  refElemet.setValue('');
                  setInitial('');
                  setState(null);
                  fileInputRef.current.value='';
                  userOnMouseLeave();
                }
                }
                className="h-4 w-4 absolute -top-3 -right-2.5"
                src={reject}
                alt=""
              />
            </div>
          ) : (
            <div className="w-28 h-28 rounded-md text-center text-gray-400  bg-gray-200 m-auto flex items-center justify-center">
              <FontAwesomeIcon icon={faUser} size="4x" />
            </div>
          )}

          <input
            ref={fileInputRef}
            type="file"
            name="file"
            className="cursor-pointer"
            accept=".jpg,.png,.jpeg"
            onChange={handleChange}
          />
        </div>
        {isLoading && <Loader height='20' />}
        {errors["profileImageUrl"] && touched["profileImageUrl"] && (
          <p className="text-sm text-red-500 2xs:text-3xs xs:text-2xs sm:text-2xs md:text-2xs lg:text-xs xl:text-xs 2xl:text-xs small">
            {errors["profileImageUrl"]}
          </p>
        )}
        {error && (
          <p className="text-sm text-red-500 2xs:text-3xs xs:text-2xs sm:text-2xs md:text-2xs lg:text-xs xl:text-xs 2xl:text-xs small">
            {error}
          </p>
        )}
      </div>
      <div className='flex flex-col justify-center items-center border-b pb-0 mx-5 border-gray-300'>
        {subForm && subForm.map(sf => ((sf.inputProps.name === "info.worksAt" && enableCompanyName) || sf.inputProps.name !== "info.worksAt") ?
          <Field
            key={sf.key}
            {...sf}
            inputProps={{
              ...sf.inputProps,
              onChange: (e) => setFieldValue(sf.inputProps.name, e.target.value),
              value: getValueFromName(values, sf.inputProps.name)
            }}
            errorText={getValueFromName(errors, sf.inputProps.name)}
          /> :
          <p className="text-sm pt-1" key={sf.key}>
            works at{" "}
            <span
              className="text-gray-400 cursor-pointer"
              onClick={() => setCompanyName(true)}
            >
              XYZ Company
            </span>
          </p>
        )}
      </div>
    </div>
  );
};

ProfileImageComponent.propTypes = {
  profileImage: PropTypes.string,
  value: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  isEdit: PropTypes.bool,
  name: PropTypes.string,
  subForm: PropTypes.array,
  wrapperStyles: PropTypes.string,
};

export default ProfileImageComponent