import _ from "lodash";
import { setActiveItem } from "../../../utils/UtilsFunc";
import {
    CHANGE_DASHBOARD_SELECTED_TAB,
    CREATE_DASHBAORD_NOTE_SUCCESS,
    CREATE_DASHBAORD_TASK_SUCCESS,
    EDIT_DASHBAORD_NOTE_SUCCESS,
    FETCH_DASHBOARD_NOTES_SUCCESS,
    TOGGLE_DASHBAORD_NOTE_SUCCESS,
    FETCH_DASHBAORD_TASKS_SUCCESS,
    FETCH_DASHBOARD_OPEN_REQUESTS_SUCCESS,
    FETCH_DASHBOARD_SPECIAL_DAYS_SUCCESS,
    EDIT_DASHBOARD_TASK_SUCCESS,
    RESET_NOTES,
    DELETE_NOTE,
    RESET_TASKS,
    DELETE_TASK
} from "../../actions/dashboard/dashboard.constant";

function reducer(state = INIT_STATE, action) {
    switch (action.type) {
        case CHANGE_DASHBOARD_SELECTED_TAB:
            return {
                ...state,
                tabs: setActiveItem(_.cloneDeep(state.tabs), action.index),
                selectedTab: action.index,
            };
        case FETCH_DASHBOARD_NOTES_SUCCESS: {
            const { data, total, nextPage } = action;
            let newNotes = [...state.notes, ...data.filter(item => state.notes.every(note => note.id !== item.id))];
            return {
                ...state,
                notes: newNotes,
                notesScroll: {
                    ...state.notesScroll,
                    page: nextPage,
                    hasMore: total > newNotes.length,
                },
            };
        }

        case FETCH_DASHBAORD_TASKS_SUCCESS: {
            const { data, total, nextPage } = action;
            const newTasks = [...state.tasks, ...data.filter(item => state.tasks.every(task => task.id !== item.id))];
            return {
                ...state,
                tasks: nextPage === 1 ? data : newTasks,
                tasksScroll: {
                    ...state.tasksScroll,
                    page: nextPage,
                    hasMore: total > (nextPage === 1 ? data.length : newTasks.length),
                }
            };
        }
        case CREATE_DASHBAORD_NOTE_SUCCESS:
            var pinnedNotes = [];
            var unpinnedNotes = [];
            state.notes.forEach((note) => {
                if (note.isPinned) pinnedNotes.push(note);
                else unpinnedNotes.push(note);
            });
            return {
                ...state,
                notes: [...pinnedNotes, action.note, ...unpinnedNotes],
            };
        case CREATE_DASHBAORD_TASK_SUCCESS:
            return { ...state, tasks: [action.task, ...state.tasks] };
        case EDIT_DASHBAORD_NOTE_SUCCESS: {
            const { id, editedNote } = action;
            const modifiedNotes = state.notes.map((note) => {
                if (note.id === id) return editedNote;
                return note;
            });
            return { ...state, notes: modifiedNotes };
        }
        case EDIT_DASHBOARD_TASK_SUCCESS: {
            const { id, editedTask } = action;
            const modifiedTasks = state.tasks.map(task => {
                if (task.id === id) return editedTask;
                return task;
            });
            return { ...state, tasks: modifiedTasks };
        }
        // case TOGGLE_DASHBAORD_NOTE_SUCCESS:
        //     var notes = [...state.notes.map((item) => (item.id === action.id) ? action.editedNote : item)]
        //     notes = notes.sort((a, b) => {
        //         if (a.isPinned && b.isPinned) {
        //             let date1 = DateTime.fromISO(a.createdAt);
        //             let date2 = DateTime.fromISO(b.createdAt);
        //             if (date1.startOf("day") <= date2.startOf("day")) return -1;
        //             return 1;
        //         }
        //         if (!a.isPinned && !b.isPinned) {
        //             let date1 = DateTime.fromISO(a.createdAt);
        //             let date2 = DateTime.fromISO(b.createdAt);
        //             if (date1.startOf("day") <= date2.startOf("day")) return -1;
        //             return 1;
        //         }
        //         if (a.isPinned) return -1;
        //         return 1;
        //     });
        //     return { ...state, notes: notes };
        case TOGGLE_DASHBAORD_NOTE_SUCCESS: {
            return {
                ...state,
                pinned: action.data.message
            }
        }
        case FETCH_DASHBOARD_OPEN_REQUESTS_SUCCESS:
            return { ...state, openRequests: action.openRequests };
        case FETCH_DASHBOARD_SPECIAL_DAYS_SUCCESS:
            return { ...state, specialDays: action.specialDays };
        case RESET_NOTES: {
            return {
                ...state,
                notes: [],
                pinned: '',
                notesScroll: {
                    page: 1,
                    limit: 10,
                    hasMore: false,
                },
            }
        }
        case RESET_TASKS: {
            return {
                ...state,
                tasks: [],
                tasksScroll: {
                    page: 1,
                    hasMore: false,
                },
            }
        }
        case DELETE_NOTE: {
            const updatedNotes = state.notes.filter(note => note.id !== action.id)
            return {
                ...state,
                notes: updatedNotes
            }
        }
        case DELETE_TASK: {
            const updatedTasks = state.tasks.filter(task => task.id !== action.id);
            return {
                ...state,
                tasks: updatedTasks
            }
        }
        default:
            return state;
    }
}

var INIT_STATE = {
    selectedTab: 0,
    notes: [],
    tasks: [],
    pinned: '',
    openRequests: [],
    specialDays: [],
    notesScroll: {
        page: 1,
        limit: 10,
        hasMore: false,
    },
    tasksScroll: {
        page: 1,
        hasMore: false,
    },
    tabs: [
        {
            id: "tab1",
            title: "Updates",
            key: "updates",
            allow: true,
            grid: 2,
            active: true,
            icon: true,
        },
        {
            id: "tab2",
            title: "Stats",
            key: "stats",
            allow: true,
            grid: 2,
            icon: true,
        },
    ],
};

export default reducer;
