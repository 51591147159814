import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useLocation } from "react-router-dom";

import Anchor from "./Anchor";
import cx from "classnames";
import Text from "./Text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import AccessCheck from "./AccessCheck";
import { useDispatch } from "react-redux";
import { forceUpdate } from "../store/actions/sidebar/sidebar.action";


const SidebarListItem = ({ type, item, classes, handleSubListOpenClick, isExpanded }) => {
    let listClass = classNames({
        "px-5": type === "label",
    });
    const dispatch = useDispatch()
    const { subList } = item;
    const { pathname, search } = useLocation();
    function handleClick(active) {
        handleSubListOpenClick(item.title, active);
    }

    const openList = item.active;

    const subListClasses = cx(
        "flex flex-col items-start transition duration-500 transition-display w-full",
        {
            hidden: !openList || !isExpanded,
            block: openList,
        }
    );

    function renderSubList() {
        return (
            <React.Fragment>
                <AccessCheck labelName={item.permKey} elementType="Label" checkStyles="">
                    <div className={`flex justify-between items-center cursor-pointer hover:bg-gold rounded-lg ${isExpanded ? "mr-2" : ""} sideBarStyle ${openList && isExpanded ? "bg-gold" : "bg-white"}`} onClick={() => handleClick(!item.active)}>
                        <Anchor
                            item={isExpanded ? item : { icon: item.icon, iconStyle: item.active ? "bg-gold p-3 mx-1 rounded-md" : "" }}
                            classes={` sideBarStyle ${classes} ${isExpanded ? "" : "justify-center"}`}
                            clickEvent={() => { }}
                        />
                        {isExpanded && <FontAwesomeIcon
                            icon={faChevronDown}
                            className="mr-3"
                            size="xs"
                        />}
                    </div>
                </AccessCheck>
                <div className={subListClasses} onClick={() => handleClick(true)}>
                    {subList.map((item) => {
                        const itemLink = item.link?.split("?").length > 0 ? item.link.split("?")[0] : item.link
                        var isSublinkActive = pathname?.trim() === itemLink?.trim();
                        let moduleName = item.permKey.replaceAll(' ', '_')
                        return (
                            <AccessCheck key={item.id} feature={moduleName} permissionType="read" elementType="Anchor" checkStyles="flex items-center w-full">
                                <Anchor
                                    redirectPath={isSublinkActive ? (pathname + search) : item.link}
                                    key={item.key}
                                    item={{ ...item, itemStyle: "ml-2 mr-2 font-bold" }}
                                    subLinkActive={isSublinkActive}
                                    classes={`w-4/5 relative pl-8 whitespace-nowrap flex flex-row my-1 items-center h-11 focus:outline-none text-gray-600 hover:text-sidebarActive border-l-4 border-transparent pr-6 mr-2 rounded-tr-lg rounded-br-lg hover:bg-gold sideBarStyle`}
                                />
                            </AccessCheck>
                        );
                    })}
                </div>
            </React.Fragment>
        );
    }

    return (
        <li className={listClass} key={item.id} onClick={() => { dispatch(forceUpdate()) }}>
            {type === "label" ? (
                <AccessCheck labelName={item.permKey} elementType="Label" checkStyles="">
                    <div className="flex flex-row items-center h-8 sideBarStyle ">
                        {isExpanded ? <div className="text-sm whitespace-nowrap font-light tracking-wide text-gray-500">
                            <Text
                                tag="small"
                                scale={true}
                                text={item.title}
                                fontweight="normal"
                                alignment="left"
                                styles="uppercase"
                            />
                        </div> : <div className="border-t border-gray-400 w-full"></div>}
                    </div>
                </AccessCheck>
            ) : subList ? (
                renderSubList()
            ) : (
                <AccessCheck key={item.key} module={item.permKey} permissionType="read" elementType="Anchor">
                    <Anchor
                        redirectPath={item.link}
                        isActive={isExpanded ? item.active : false}
                        item={{
                            ...item,
                            itemStyle: `ml-2 mr-2 font-bold list-item ${isExpanded ? "appear" : "hidden"}  `,
                            iconStyle: item.active ? "bg-gold p-3 mx-1 rounded-md" : ""
                        }}
                        // item={isExpanded ? item : { icon: item.icon, iconStyle: item.active ? "bg-blue-100 p-3 mx-1 rounded-md" : "" }}
                        classes={` relative whitespace-nowrap sideBarStyle ${classes} ${isExpanded ? "" : "justify-center"}`}
                        bagdeClasses={`bg-themeRed text-white transition-all duration-1000 ${!isExpanded ? "opacity-0 hidden" : "opacity-100"}`}
                    />
                </AccessCheck>
            )}
        </li>
    );
};

SidebarListItem.propTypes = {
    type: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
    classes: PropTypes.string,
    handleSubListOpenClick: PropTypes.func,
    isExpanded: PropTypes.bool,
};

export default SidebarListItem;
