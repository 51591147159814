import React, { useCallback, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import Image from "../Image";
import { withHistory } from "slate-history";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { apiResponseType, editorKeys } from "../../utils/enum";
import { Slate, Editable, withReact, ReactEditor } from "slate-react";
import { Range, createEditor } from "slate";
import "./Editor.css";
import Leaf from "./components/Leaf";
import Element from "./components/Element";
import { Portal } from "./components/Portal";
import taskSvg from "../../assets/img/icons/task.svg";
import taskBlue from "../../assets/img/icons/taskblue.svg";
import bold from "../../assets/img/icons/boldIcon.svg";
import italic from "../../assets/img/icons/italicIcon.svg";
import underline from "../../assets/img/icons/underlineIcon.svg";
import at from "../../assets/img/icons/atIcon.svg";
import attachment from "../../assets/img/icons/attachment.svg";
import Button from "../../commonComponent/Button";
import DefaultUser from "../DefaultUser";
import MarkButton from "./components/MarkButton";
import { getFormatTimeFromTimestamp, getTimeFromJSDate } from "../../utils/luxon";


const withMentions = (editor) => {
    const { isInline, isVoid } = editor;

    editor.isInline = (element) => {
        return element.type === "mention" ? true : isInline(element);
    };

    editor.isVoid = (element) => {
        return element.type === "mention" ? true : isVoid(element);
    };

    return editor;
};

const isValidPoint = (editor, point) => {
    const { path, offset } = point;
    const { selection } = editor;
    if (!selection) return false;
    const [start, end] = Range.edges(selection);
    const startPath = start.path;
    const endPath = end.path;
    if (path.length !== startPath.length || path.length !== endPath.length) {
        return false;
    }
    for (let i = 0; i < path.length; i++) {
        if (path[i] < startPath[i] || path[i] > endPath[i]) {
            return false;
        }
        if (path[i] === startPath[i] && offset < start.offset) {
            return false;
        }
        if (path[i] === endPath[i] && offset > end.offset) {
            return false;
        }
    }
    return true;
};

function Input({
    handleChange,
    handleBlur,
    handleSubmit,
    handleCancel,
    handleIconClick,
    errors = {},
    touched = {},
    value,
    iconsSelected = {},
    handleKeydown,
    chars,
    index,
    search,
    target,
    setMention,
    simpleEditor,
    prefix,
    suffix,
    attachments,
    handleDeleteAttachment,
    showProfileIcon,
    isLoading,
    inline,
    optionsClass = "",
    fileUploadError,
    profileImageUrl,
    cancelEditMode,
    editMode,
    date,
    fetchState = "",
}) {
    const renderElement = useCallback((props) => <Element {...props} />, []);
    const ref = useRef();
    const editor = useMemo(
        () => withMentions(withReact(withHistory(createEditor()))),
        []
    );

    const renderLeaf = useCallback((props) => {
        return <Leaf {...props} />;
    }, []);

    useEffect(() => {
        if (target && (fetchState || (!fetchState && chars.length > 0))) {
            const el = ref.current;
            const isValid = isValidPoint(editor, target?.focus)
            if (isValid) {
                const domRange = ReactEditor.toDOMRange(editor, target);
                const rect = domRange.getBoundingClientRect();
                el.style.top = `${rect.top + window.pageYOffset + 24}px`;
                el.style.left = `${rect.left + window.pageXOffset}px`;
            }
        } else {
            const el = ref.current;
            setTimeout(() => {
                el.style.top = `-9999px`;
                el.style.left = `-9999px`;
            }, 300);
        }
    }, [chars.length, editor, index, search, target]);

    // useEffect(() => {
    //     if (value && value.length > 0 && (value[0].children.length > 1 || value[0].children[0].text.length > 1)) {
    //         Transforms.select(editor, Editor.end(editor, []))
    //     }
    // })

    const hasContent = (value) => {
        return value[0]?.children?.some(
            (child) =>
                (child.text && child.text.trim().length > 0) ||
                (child.type === "mention" && child.character.length > 0)
        );
    };


    return (
        <>
            <div className="rounded-lg w-full bg-white px-2 pt-2">
                <div className="flex justify-between ">
                    {showProfileIcon && (
                        <div className="profile-pic h-12 w-12 mr-3 flex-shrink-0 rounded">
                            {profileImageUrl ? (
                                <Image
                                    imgClass="w-12 h-12 object-cover rounded"
                                    img={profileImageUrl}
                                />
                            ) : (
                                <DefaultUser />
                            )}
                        </div>
                    )}
                    <div className="input w-full relative bg-gray-100 flex items-center rounded-lg">
                        {inline.taskEnable && (
                            <div className="ml-2 flex">{prefix()}</div>
                        )}

                        <Slate
                            editor={editor}
                            value={value}
                            onChange={(value) => handleChange(value, editor)}
                        >
                            <Editable
                                className="min-h-32 h-auto w-full p-3 editor"
                                placeholder="Post Here"
                                onBlur={handleBlur}
                                spellCheck
                                renderLeaf={renderLeaf}
                                renderElement={renderElement}
                                onKeyDown={(e) => handleKeydown(e, editor)}
                            />
                            <Portal>
                                <div
                                    ref={ref}
                                    style={{
                                        top: "-9999px",
                                        left: "-9999px",
                                        position: "absolute",
                                        zIndex: 1000,
                                        padding: "10px",
                                        background: "white",
                                        minWidth: "170px",
                                        borderRadius: "4px",
                                        boxShadow: "0 1px 5px rgba(0,0,0,.2)",
                                    }}
                                    data-cy="mentions-portal"
                                >
                                    {(!fetchState || (fetchState === apiResponseType.SUCCESS && chars.length > 0)) ? 
                                    chars.map((char, i) => (
                                        <div
                                            key={char}
                                            className="mention-item"
                                            onClick={(e) =>
                                                setMention(e, editor, i)
                                            }
                                            style={{
                                                backgroundColor: `${
                                                    i === index
                                                        ? "#b4d5ff"
                                                        : "transparent"
                                                }`,
                                            }}
                                            dangerouslySetInnerHTML={{
                                                __html: char,
                                            }}
                                        ></div>
                                    )) :
                                        ((fetchState === apiResponseType.SUCCESS && chars.length === 0) ?
                                            <p className="mention-item">No Data Found.</p>
                                            : fetchState === apiResponseType.FETCHING ?
                                                <p className="mention-item">Loading...</p>
                                                : <p className="mention-item">Error</p>
                                        )}
                                </div>
                            </Portal>
                        </Slate>

                        {inline.taskEnable && suffix()}

                        {editMode && (
                            <div className="h-full flex items-start mt-4">
                                <Button
                                    rounded="lg"
                                    color="white"
                                    btnColor="bg-sidebarActive"
                                    text="Post"
                                    width="12"
                                    fweight="fnormal"
                                    styles="h-auto mr-2 p-1"
                                    action={(e) => handleSubmit(e, editor)}
                                />
                                <Button
                                    rounded="lg"
                                    color="gray"
                                    text="Cancel"
                                    width="12"
                                    fweight="fnormal"
                                    styles="h-auto mr-3 p-1"
                                    action={cancelEditMode}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className={`icons-row w-full mt-4 pl-4 ${optionsClass}`}>
                    <div className="w-full flex items-center justify-between">
                    <div className="flex items-center">
                        <MarkButton
                            icon={bold}
                            handler={(e) => handleIconClick(e, editor, editorKeys.bold)}
                            styles={`cursor-pointer mr-2 w-3 h-3 ${
                                iconsSelected[editorKeys.bold]
                                    ? "border border-black shadow-sm"
                                    : ""
                            }`}
                            isVisible={inline.bold}
                        />

                        <MarkButton
                            icon={italic}
                            handler={(e) => handleIconClick(e, editor, editorKeys.italic)}
                            styles={`cursor-pointer mr-2 w-3 h-3 ${
                                iconsSelected[editorKeys.italic]
                                    ? "border border-black shadow-sm"
                                    : ""
                            }`}
                            isVisible={inline.italic}
                        />
                        <MarkButton
                            icon={underline}
                            handler={(e) =>  handleIconClick(e, editor, editorKeys.underline)}
                            styles={`cursor-pointer mr-2 w-3 h-3 ${
                                iconsSelected[editorKeys.underline]
                                    ? "border border-black shadow-sm"
                                    : ""
                            }`}
                            isVisible={inline.underline}
                        />
                        {inline.at && (
                            <img
                                src={at}
                                size="xs"
                                fontWeight="normal"
                                onClick={(e) =>
                                    handleIconClick(e, editor, editorKeys.at)
                                }
                                className={`cursor-pointer mr-2 w-3 h-3 ${
                                    iconsSelected[editorKeys.link]
                                        ? "border border-black shadow-sm"
                                        : ""
                                }`}
                            />
                        )}
                        {inline.link && (
                            <img
                                src={attachment}
                                size="xs"
                                fontWeight="normal"
                                onClick={(e) =>
                                        hasContent(value)
                                            ? handleIconClick(
                                                  e,
                                                  editor,
                                                  editorKeys.link
                                              )
                                            : null
                                }
                                className={`cursor-pointer mr-2 w-5 h-5 ${
                                    iconsSelected[editorKeys.link]
                                        ? "border border-black shadow-sm"
                                        : ""
                                }${
                                        !hasContent(value)
                                            ? "opacity-50 cursor-not-allowed"
                                            : ""
                                }`}
                            />
                        )}
                        {inline.taskEnable ? (
                            iconsSelected.toggle ? (
                                <img
                                    src={taskBlue}
                                    size="xs"
                                    fontWeight="normal"
                                    // onClick={(e) =>
                                    //     handleIconClick(
                                    //         e,
                                    //         editor,
                                    //         editorKeys.toggle
                                    //     )
                                    // }
                                    className="w-4 h-4 cursor-not-allowed"
                                />
                            ) : (
                                <img
                                    src={taskSvg}
                                    size="xs"
                                    fontWeight="normal"
                                    // onClick={(e) =>
                                    //     handleIconClick(
                                    //         e,
                                    //         editor,
                                    //         editorKeys.toggle
                                    //     )
                                    // }
                                    className="w-4 h-4 cursor-pointer-allowed"
                                />
                            )
                        ) : null}

                        </div>
                        <div>
                            {date ? (<span> {
                                getFormatTimeFromTimestamp(getTimeFromJSDate(date, "yyyy-MM-dd"), "DD")
                            }</span>) : null}
                        </div>
                    </div>
                </div>
                <div className="flex pl-4 pt-4 flex-col">
                    {attachments.map((attachment, idx) => (
                        <div key="attachment mb-2">
                            <span
                                onClick={() => {}}
                                className="mb-1 inline-flex items-center justify-between px-3 py-2 mr-2 text-xs font-bold leading-none text-black bg-themeLightBlue rounded-full relative break-all"
                            >
                                {attachment?.attachmentName}
                                <span className="ml-2 cursor-pointer">
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        className="text-yellow"
                                        onClick={() =>
                                            handleDeleteAttachment(idx)
                                        }
                                    />
                                </span>{" "}
                            </span>
                        </div>
                    ))}
                    <p>{isLoading && "Uploading..."}</p>
                    {fileUploadError && (
                        <p className="text-sm text-red-500 2xs:text-3xs xs:text-2xs sm:text-2xs md:text-2xs lg:text-xs xl:text-xs 2xl:text-xs small">
                            {fileUploadError}
                        </p>
                    )}
                    {errors?.size && (
                        <p className="text-sm text-red-500 2xs:text-3xs xs:text-2xs sm:text-2xs md:text-2xs lg:text-xs xl:text-xs 2xl:text-xs small">
                            {errors?.size}
                        </p>
                    )}
                </div>
            </div>
        </>
    );
}

Input.propTypes = {
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleCancel: PropTypes.func,
    errors: PropTypes.object,
    touched: PropTypes.object,
    handleIconClick: PropTypes.func,
    value: PropTypes.array,
    iconsSelected: PropTypes.object,
    handleKeydown: PropTypes.func,
    chars: PropTypes.array,
    index: PropTypes.number,
    target: PropTypes.string,
    search: PropTypes.string,
    setMention: PropTypes.func,
    simpleEditor: PropTypes.bool,
    prefix: PropTypes.func,
    suffix: PropTypes.func,
    attachments: PropTypes.array,
    handleDeleteAttachment: PropTypes.func,
    showProfileIcon: PropTypes.bool,
    isLoading: PropTypes.bool,
    inline: PropTypes.object,
    optionsClass: PropTypes.string,
    fileUploadError: PropTypes.string,
    profileImageUrl: PropTypes.string,
    cancelEditMode: PropTypes.func,
    editMode: PropTypes.bool,
    date: PropTypes.string,
    fetchState: PropTypes.string,
};

export default Input;
