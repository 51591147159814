export const CHANGE_DASHBOARD_SELECTED_TAB = "CHANGE_DASHBOARD_SELECTED_TAB";
export const FETCH_DASHBOARD_NOTES_SUCCESS = "FETCH_DASHBOARD_NOTES_SUCCESS";
export const FETCH_DASHBAORD_TASKS_SUCCESS = "FETCH_TASKS_SUCCESS";
export const CREATE_DASHBAORD_NOTE_SUCCESS = "CREATE_NOTE_SUCCESS";
export const CREATE_DASHBAORD_TASK_SUCCESS = "CREATE_TASK_SUCCESS";
export const EDIT_DASHBAORD_NOTE_SUCCESS = "EDIT_NOTE_SUCCESS";
export const EDIT_DASHBOARD_TASK_SUCCESS = "EDIT_TASK_SUCCESS";
export const TOGGLE_DASHBAORD_NOTE_SUCCESS = "TOGGLE_NOTE_SUCCESS";
export const FETCH_DASHBOARD_OPEN_REQUESTS_SUCCESS = "FETCH_DASHBOARD_OPEN_REQUESTS_SUCCESS";
export const FETCH_DASHBOARD_SPECIAL_DAYS_SUCCESS = "FETCH_DASHBOARD_SPECIAL_DAYS_SUCCESS";
export const RESET_NOTES = 'RESET_NOTES';
export const DELETE_NOTE = "DELETE_NOTE";
export const RESET_TASKS = 'RESET_TASKS';
export const DELETE_TASK = "DELETE_TASK";