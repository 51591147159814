import { useContext, useEffect, useMemo, useState } from "react";
import isHotkey from "is-hotkey";
import {
    deserialize,
    HOTKEYS,
    insertMention,
    plainSerialize,
    serializeFunc,
    toggleMark,
} from "../utils/UtilsFunc";
import { Editor, Transforms, Range } from "slate";
import _ from "lodash";
import { editorKeys, notesTypes } from "../utils/enum";
import { useFileUpload } from "./useFileUpload";
import getRequest from "../network/getRequest";
import { UserContext } from "../contextAPI";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

const EDITOR_INIT_STATE = [
    {
        type: "paragraph",
        children: [{ text: "" }],
    },
];

function useEditor({
    prefix,
    suffix,
    onSubmit,
    showProfileIcon = false,
    atOptions,
    inline,
    currentUserId,
    cleanState,
    userOptions,
    trackTaggedUser = false,
    date = undefined,
    showErrorInToast = false,
    tab,
    fileMimeTypes = null,
    fileMimeTypesErrorMsg = "",
    type = notesTypes.dashboard,
    isRepeatable = false,
    activeTab,
}) {
    const [value, setValue] = useState(EDITOR_INIT_STATE);
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});
    const [iconsSelected, setIconsSelected] = useState({});
    const [target, setTarget] = useState();
    const [index, setIndex] = useState(0);
    const [search, setSearch] = useState("");
    const [attachments, setAttachments] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [editTaskMode, setEditTaskMode] = useState(false);
    const [userIdList, setUserIdList] = useState([]);
    const [selection, setSelection] = useState(new Set());
    const { fetchRequest } = getRequest();
    const { dHToast } = useContext(UserContext);
    const [gotTaggedList, setGotTaggedList] = useState(false)


    const chars = atOptions
        .filter((c) => {
            if (c && c.length) {
                if (type === notesTypes.dashboard) {
                    const searchableStr = c.toLowerCase().split("<div")
                    if (searchableStr && searchableStr.length > 0) {
                        return searchableStr[0].includes(search.toLowerCase())
                    }
                }
                else {
                    return c.toLowerCase().startsWith(search.toLowerCase())
                }
            }
            return false
        })
        .slice(0, 10);

    const [simpleEditor, setSimpleEditor] = useState(false);
    var { upload, imageMeta, fileName, isLoading, error } = useFileUpload({
        pathToUpload: "fileAttachment",
        validation: function (file) {
            if (file) {
                const filename = file.name;
                const ext = filename.substr(filename.lastIndexOf(".") + 1);
                const kb = file.size / 1024;
                const mb = kb / 1024;  // Convert KB to MB
                const mimeTypeAllowed = ["jpg", 'jpeg', "png", "pdf"];
                if (!fileMimeTypes && !mimeTypeAllowed.includes(ext?.toLowerCase())) {
                    return "File format accepted only .jpg,.jpeg,.png,.pdf up to 15 MB";
                }
                else if(fileMimeTypes && !fileMimeTypes.includes(ext?.toLowerCase())){
                    return fileMimeTypesErrorMsg;
                }
                if (mb > 15) {  // Check for 15 MB limit
                    if(fileMimeTypes){
                        return fileMimeTypesErrorMsg;
                    }
                    return "File format accepted only .jpg,.jpeg,.png,.pdf up to 15 MB";
                }

                return true;
            }
        },
        showErrorInToast: showErrorInToast
    });

    useEffect(() => {
        if (!imageMeta?.attachmentUrl) return;
        setAttachments((attachments) => [
            ...attachments,
            {
                attachmentName: fileName,
                attachmentUrl: imageMeta.attachmentUrl,
                id: imageMeta.id
            },
        ]);
    }, [imageMeta?.attachmentUrl]);

    useEffect(() => {
        return () => {
            setAttachments([]);
        };
    }, []);

    const getTaggedList = (arr, isEditing = false) => {
        const taggedList = arr.filter(c => c.type === "mention").map(c => c.clientId ? { clientId: c.clientId } : { userId: c.userId })
        setUserIdList(taggedList)
        if (isEditing) {
            setGotTaggedList(true)
        }
    }

    useEffect(() => {
        getTaggedList(value[0].children)
    }, [value])

    useEffect(() => {
        if (activeTab === "tasks") {
            setSimpleEditor(true);
            setIconsSelected(prev => ({...prev, toggle: true}));
        } else {
            setSimpleEditor(false);
            setIconsSelected(prev => _.omit(prev, "toggle"));
        }
    }, [activeTab]);

    function handleChange(value, editor) {
        setValue(value);
        const { selection } = editor;

        var plainText = plainSerialize(value);
        if (simpleEditor && plainText.length <= 1500) {
            setErrors({});
        }

        if (simpleEditor && plainText.length <= 150) {
            setErrors({});
        }

        if (selection && Range.isCollapsed(selection)) {
            const [start] = Range.edges(selection);
            const wordBefore = Editor.before(editor, start, { unit: "word" });
            const before = wordBefore && Editor.before(editor, wordBefore);
            const beforeRange = before && Editor.range(editor, before, start);
            const beforeText =
                beforeRange && Editor.string(editor, beforeRange);
            const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/);
            const after = Editor.after(editor, start);
            const afterRange = Editor.range(editor, start, after);
            const afterText = Editor.string(editor, afterRange);
            const afterMatch = afterText.match(/^(\s|$)/);

            if (beforeMatch && afterMatch) {
                setTarget(beforeRange);
                setSearch(beforeMatch[1]);
                setIndex(0);
                return;
            }
        }

        setTimeout(() => {
            setTarget(null);
        }, 300);
    }

    function handleBlur() {
        setTouched(true);
    }

    function extractEmails(text) {
        return text.match(
            /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
        );
    }

    function getClientIdByEmail(email) {
        const client = userOptions.find((item) => (item?.email === email || item?.user?.email === email));
        return client.id;
    }

    const getSelectedClientList = (id) => {
        setSelection(toggleItem(selection, id));
        const clientIdList = Array.from(toggleItem(selection, id));
        setUserIdList(prevState => prevState.concat(clientIdList.map(cId => type === notesTypes.dashboard ? { clientId: cId } : { userId: cId })));
    }

    const extractedId = (selectedId) => {
        return selectedId && selectedId.match(/([a-z0-9-]+[a-z0-9_-])/gi)[3]
    }
    function setMention(event, editor, i) {
        event.preventDefault();
        let id;
        if (trackTaggedUser) {
            //This code has been commented because now we are getting values of clients instead of user.
            // const email = extractEmails(chars[i]);
            // id = getClientIdByEmail(email[0]);
            if (chars.length) {
                const selectedId = (chars[i]?.split('|')?.length > 1 && getClientIdByEmail(chars[i]?.split('|')[1]?.trim()))
                    || chars[i].split('<')[1]?.trim();
                id = chars[i]?.split('|')?.length > 1 ? selectedId :
                    userOptions.find(item => item.id === extractedId(selectedId)) &&
                    userOptions.find(item => item.id === extractedId(selectedId))?.id || {};

                if (typeof id === "object" && Object.keys(id).length === 0) {
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(chars[i], "text/html");
                    id = doc.body.getElementsByTagName("div")[0].id
                }
                getSelectedClientList(id);
            }

        }
        if ((trackTaggedUser && id) || !trackTaggedUser) {
            if (target) {
                Transforms.select(editor, target);
            }
            insertMention(
                editor,
                chars[i] ? (chars[i]?.split('|')?.length > 1 && chars[i]?.split('|')[0]?.trim() ||
                    chars[i].split('<')[0]?.trim()
                ) : "",
                id,
                type
            );
        }
    }

    const addItem = (set, id) => {
        if (set.has(id)) return set;
        const newSet = new Set(set);
        newSet.add(id);
        return newSet;
    };

    const deleteItem = (set, id) => {
        if (!set.has(id)) return set;
        const newSet = new Set(set);
        newSet.delete(id);
        return newSet;
    };

    const toggleItem = (set, id) => {
        return addItem(set, id);
    }
    function handleKeydown(event, editor) {
        if (event.key === "Enter" && !event.shiftKey && target === null) {
            handleSubmit(event, editor);
            return;
        }

        if (event.key === "Enter" && !event.shiftKey && target !== null) {
            event.preventDefault();
            let id;
            Transforms.select(editor, target);
            if (trackTaggedUser) {
                // const email = extractEmails(chars[index]);
                // id = getClientIdByEmail(email[0]);
                // id = userOptions && userOptions[i]['user'].id;
                if (chars.length) {
                    const selectedId = (chars[index]?.split('|').length > 1 && getClientIdByEmail(chars[index]?.split('|')[1].trim()))
                        || chars[index].split('<')[1]?.trim();
                    id = chars[index]?.split('|')?.length > 1 ? selectedId :
                        userOptions.find(item => item.id === extractedId(selectedId)) &&
                        userOptions.find(item => item.id === extractedId(selectedId)).id || {};

                    if (typeof id === "object" && Object.keys(id).length === 0) {
                        const parser = new DOMParser();
                        const doc = parser.parseFromString(chars[index], "text/html");
                        id = doc.body.getElementsByTagName("div")[0].id
                    }
                    getSelectedClientList(id);
                }
            }
            if ((trackTaggedUser && id) || !trackTaggedUser) {
                insertMention(
                    editor,
                    chars[index] ? (chars[index]?.split('|').length > 1 && chars[index]?.split('|')[0].trim())
                        || chars[index].split('<')[0].trim() : "",
                    id,
                    type
                );
            }
            setTarget(null);
            return;
        }

        if (target) {
            switch (event.key) {
                case "ArrowDown":
                    event.preventDefault();
                    var prevIndex = index >= chars.length - 1 ? 0 : index + 1;
                    setIndex(prevIndex);
                    break;
                case "ArrowUp":
                    event.preventDefault();
                    var nextIndex = index <= 0 ? chars.length - 1 : index - 1;
                    setIndex(nextIndex);
                    break;
                case "Enter": {
                    event.preventDefault();
                    Transforms.select(editor, target);
                    let id;
                    if (trackTaggedUser && chars.length) {
                        const selectedId = (chars[index]?.split('|').length > 1 && getClientIdByEmail(chars[index]?.split('|')[1].trim()))
                            || chars[index].split('<')[1]?.trim();
                        id = chars[index]?.split('|')?.length > 1 ? selectedId :
                            userOptions.find(item => item.id === extractedId(selectedId)) &&
                            userOptions.find(item => item.id === extractedId(selectedId)).id || {};

                        if (typeof id === "object" && Object.keys(id).length === 0) {
                            const parser = new DOMParser();
                            const doc = parser.parseFromString(chars[index], "text/html");
                            id = doc.body.getElementsByTagName("div")[0].id
                        }
                        getSelectedClientList(id);
                    }
                    if ((trackTaggedUser && id) || !trackTaggedUser) {
                        insertMention(
                            editor,
                            chars[index] ? (chars[index]?.split('|').length > 1 && chars[index]?.split('|')[0].trim())
                                || chars[index].split('<')[0].trim() : "",
                            id,
                            type
                        );
                    }
                    setTarget(null);
                    break;
                }
                case "Escape":
                    event.preventDefault();
                    setTarget(null);
                    break;
            }
        }

        for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event)) {
                event.preventDefault();
                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
                if (iconsSelected[mark]) {
                    setIconsSelected(_.omit(iconsSelected, mark));
                } else {
                    setIconsSelected({ ...iconsSelected, [mark]: true });
                }
            }
        }
    }


    function handleFileChange(e) {
        var file = e.target.files[0];
        upload(file);
    }

    function resetState() {
        setValue(EDITOR_INIT_STATE);
        setAttachments([]);
        setErrors(null);
        setTouched(false);
        setTarget(null);
        setIndex(0);
        setSearch("");
        setEditMode(false);
        setEditTaskMode(false);
        if (activeTab !== "tasks") {
            setSimpleEditor(false);
            setIconsSelected({});
        } else {
            setIconsSelected(prev => ({...prev, toggle: true}));
        }
        setUserIdList([]);
    }

    function handleDeleteAttachment(idx) {
        fetchRequest({}, "delete", "fileAttachment", [
            attachments[idx].id,
        ]).then((res) => {
            if (res.error) {
                dHToast(true, res.message, "rt", "error", faExclamationCircle);
            }
            if (res.message === "Attachment Deleted") {
                dHToast(true, "Attachment deleted successfully.", "rt", "error", faExclamationCircle);
            }
        }).catch((err) => {
            dHToast(true, err?.message, "rt", "error", faExclamationCircle);
        });
        let newAttchments = [...attachments];
        newAttchments.splice(idx, 1);
        setAttachments(newAttchments);
    }

    function enableEdit(state) {
        const { note, attachments, ownerId, task, description } = state;
        const parser = new DOMParser();
        if (task) {
            const doc = parser.parseFromString(description, "text/html");
            const body = deserialize(doc.body);
            getTaggedList(body[0].children, true)
            if (body[0].children[body[0].children.length - 1]?.type === "mention") {
                body[0].children.push({ text: "" })
            }
            setValue(body);
            setAttachments(attachments);
            setIconsSelected({ ...iconsSelected, toggle: true });
            setSimpleEditor(() => () => true);
            setEditTaskMode(true);
            setEditMode(false);
            setSelection(new Set())
            return true;
        }
        if (ownerId === currentUserId) {
            const doc = parser.parseFromString(note, "text/html");
            const body = deserialize(doc.body);
            getTaggedList(body[0].children, true)
            if (body[0].children[body[0].children.length - 1]?.type === "mention") {
                body[0].children.push({ text: "" })
            }
            setValue(body);
            setAttachments(attachments);
            setIconsSelected({ ...iconsSelected, toggle: false });
            setSimpleEditor(() => () => true);
            setEditMode(true);
            setEditTaskMode(false);
            setSelection(new Set())
            return true;
        }
        return false;
    }

    function cancelEditMode() {
        resetState();
    }
    const handleOnCLick = (e) => {
        e.target.value = null;
    }
    var inputElement = useMemo(() => {
        const input = document.createElement("input");
        input.type = "file";
        input.onchange = handleFileChange;
        input.onclick = handleOnCLick
        input.accept = ".jpg,.jpeg,.png,.pdf";
        return input;
    }, []);

    function handleCleanState() {
        if (_.isFunction(cleanState)) cleanState();
    }

    function handleIconClick(e, editor, item) {
        e?.preventDefault();
        if (item === editorKeys.at) {
            editor.insertNode({
                text: " @",
            });
            return;
        }

        if (item === editorKeys.link) {
            inputElement.click();
            return;
        }

        if (item === editorKeys.toggle) {
            if (iconsSelected[item]) {
                setIconsSelected(_.omit(iconsSelected, item));
            } else {
                setIconsSelected({ ...iconsSelected, [item]: true });
            }
            handleCleanState();
            resetState();
            setSimpleEditor(() => !iconsSelected?.toggle);
        }

        let newIconsState;

        if (iconsSelected[item]) {
            newIconsState = _.omit(iconsSelected, item);
        } else {
            newIconsState = { ...iconsSelected, [item]: true };
        }

        setIconsSelected(newIconsState);

        if (item === editorKeys.bold) {
            toggleMark(editor, editorKeys.bold);
        }

        if (item === editorKeys.italic) {
            toggleMark(editor, editorKeys.italic);
        }

        if (item === editorKeys.underline) {
            toggleMark(editor, editorKeys.underline);
        }



    }

    function handleSubmit(e, editor) {

        e.preventDefault();
        var { html, clientIds } = serializeFunc({
            children: value,
        });

        const anyMentionsIdx = value[0].children.findIndex(el => (el.type === "mention" && el.character.length > 0))
        var plainText = plainSerialize(value);
        if (!plainText?.trim() && anyMentionsIdx === -1) return;
        if (tab === "" && plainText?.trim().length > 1500) {//&& editMode) { //max 1500 char limit for notes
            return setErrors({
                size: "Maximum of 1500 characters are allowed",
            });
        }
        if (tab !== "" && plainText?.length > 200) {//&& !editMode) { //150 for task
            return setErrors({
                size: "Maximum of 200 characters are allowed",
            });
        }
        setGotTaggedList(false)
        onSubmit({
            note: html,
            //users:clientIds,
            attachments,
            // attachments: attachments?.map(({ id, ...el }) => el),

        });
        Transforms.select(editor, {
            anchor: { path: [0, 0], offset: 0 },
            focus: { path: [0, 0], offset: 0 },
        });
        // if (e.key === "Enter") {
        //     Transforms.select(editor, Editor.end(editor, []))
        // }
        // date is '' then don't call resetState()
        // date is available  and undefined then call resetState()
        isRepeatable ? resetState() : _.isNil(date) ? resetState() : _.isObject(date) ? resetState() : Transforms.select(editor, Editor.end(editor, []));
    }

    return {
        handleChange,
        handleBlur,
        handleIconClick,
        value,
        errors,
        touched,
        iconsSelected,
        handleKeydown,
        chars,
        target,
        index,
        search,
        setMention,
        handleSubmit,
        simpleEditor,
        prefix,
        suffix,
        attachments,
        handleDeleteAttachment,
        showProfileIcon,
        isLoading,
        inline,
        fileUploadError: error,
        enableEdit,
        cancelEditMode,
        editMode,
        editTaskMode,
        userIdList,
        date,
        gotTaggedList,
        setSearch,
    };
}

export default useEditor;
