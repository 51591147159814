import {
    CHANGE_HOUSEHOLD_SELECTED_TAB,
    CREATE_BILLING_INFORMATION_SUCCESS,
    CREATE_FINANCIAL_PLANNING_INFO,
    FETCH_BILLING_INFO_SUCCESS,
    FETCH_HOUSEHOLD_GENERAL_INFO_SUCCESS,
    FETCH_INITIAL_REQUEST_START,
    UPDATE_BILLING_INFORMATION_SUCCESS,
    UPDATE_FINANCIAL_PLANNING_INFO,
    CHANGE_HOUSEHOLD_STATUS,
    FETCH_CLIENT_HOUSEHOLD_BILLING_SUCCESS,
    CREATE_PAYOUT_BLOCK_INFO,
    ADD_NEW_PAYOUT_BLOCK_INFO,
    DELETE_NEW_PAYOUT_BLOCK_INFO,
    CHANGE_REFERENCE,
    GET_EXCLUDED_ASSETS,
    RESET_PAY_BLOCK_LIST,
    RESET_EXCLUDED_ASSETS,
    LIVE_BILL_LIST,
    CLEAR_HOUSEHOLD_GENERAL_INFO,
    FETCH_FINANCIAL_PLANNING_INFO_SUCCESS,
    CLEAR_HOUSEHOLD_BILLING_INFO,
    CLEAR_HOUSEHOLD_BILLING_INFORMATION
} from "../../actions/billing/household/household.constant";
import { setActiveItem, setActiveTab } from "../../../utils/UtilsFunc";
import { fetchFinanceInfoAsyncThunk } from "../../actions/billing/household/household.action";
import { PRIMARY } from "../../../constants/constant";

function reducer(state = INIT_STATE, action) {
    switch (action.type) {
        case CHANGE_HOUSEHOLD_SELECTED_TAB:
            return {
                ...state,
                tabs: setActiveItem(state.tabs, action.index),
                selectedTabIndex: action.index,
            };
        case FETCH_INITIAL_REQUEST_START:
            return {
                ...state,
                tabs: setActiveTab(state.tabs, INIT_STATE.selectedTabIndex),
                selectedTabIndex: INIT_STATE.selectedTabIndex, // Added 0 to open the General Tab after routing to the other page from other tabs 
                isInitialRequestComplete: false
            };
        case CHANGE_REFERENCE:
            return {
                ...state,
                generalInfo: { ...state.generalInfo },
            };
        case FETCH_HOUSEHOLD_GENERAL_INFO_SUCCESS:
            var {
                wealthAdvisor,
                businessDeveloper,
                wealthAdvisor2,
                financialPlanner,
                solicitor,
                managedAssets,
                ...data
            } = action.data;
            var primaryKeyIndex = action.data.members?.findIndex(item => item?.subType === PRIMARY);
            var firstname = primaryKeyIndex >= 0 ? (action.data.members[primaryKeyIndex]?.firstName) : ''
            var lastName = primaryKeyIndex >= 0 ? (action.data.members[primaryKeyIndex]?.lastName) : ''

            return {
                ...state,
                isInitialRequestComplete: true,
                generalInfo: {
                    managedAssets: managedAssets,
                    contact: data.contact,
                    clientBilling: {
                        ...data.clientBilling,
                        firstName: firstname,
                        lastName: lastName !== "undefined" ? lastName : "",
                    },
                    wealthAdvisor: {
                        title: wealthAdvisor?.fullName || '',
                        value: wealthAdvisor?.id || '',
                        profileImageUrl: wealthAdvisor?.profileImageUrl || '',
                    },
                    wealthAdvisor2: {
                        title: wealthAdvisor2?.fullName || '',
                        value: wealthAdvisor2?.id || '',
                        profileImageUrl: wealthAdvisor2?.profileImageUrl || '',
                    },
                    businessDeveloper: {
                        title: businessDeveloper?.fullName || '',
                        value: businessDeveloper?.id || '',
                        profileImageUrl: businessDeveloper?.profileImageUrl || '',
                    },
                    solicitor: {
                        title: solicitor?.fullName || '',
                        value: solicitor?.id || '',
                        profileImageUrl: solicitor?.profileImageUrl || '',
                    },
                    financialPlanner: {
                        title: financialPlanner?.fullName || '',
                        value: financialPlanner?.id || '',
                        profileImageUrl: financialPlanner?.profileImageUrl || '',
                    },
                    other: {
                        name: data.name,
                        client: data.id,
                        profileImageUrl: data.profileImageUrl,
                        rating: data.rating,
                        members: data.members,
                        tier: data.tier,
                        introductionsCount:data.introductionsCount
                    },
                },
            };
        case `${fetchFinanceInfoAsyncThunk.pending}`:
            return { ...state, isInitialRequestComplete: false };
        case `${fetchFinanceInfoAsyncThunk.fulfilled}`:
            return {
                ...state,
                isInitialRequestComplete: true,
                financialPlanning: action.payload ? action.payload : {},
            };
        case FETCH_BILLING_INFO_SUCCESS:
            if (!action.data)
                return {
                    ...state,
                    isInitialRequestComplete: true,
                    billingInformation: {},
                };
            return {
                ...state,
                billingInformation: action.data,
                isInitialRequestComplete: true,
            };
        case FETCH_FINANCIAL_PLANNING_INFO_SUCCESS:
            if (!action.data)
                return {
                    ...state,
                    isInitialRequestComplete: true,
                    financialPlanning: {},
                };
            return {
                ...state,
                financialPlanning: action.data,
                isInitialRequestComplete: true,
            };
        case CREATE_FINANCIAL_PLANNING_INFO:
        case UPDATE_FINANCIAL_PLANNING_INFO:
            return { ...state, financialPlanning: action.data };
        case UPDATE_BILLING_INFORMATION_SUCCESS:
        case CREATE_BILLING_INFORMATION_SUCCESS:
            return { ...state, billingInformation: action.data };
        case FETCH_CLIENT_HOUSEHOLD_BILLING_SUCCESS: {
            return {
                ...state,
                householdBilling: action.householdBilling.billingDetail,
                totalResults: action.householdBilling.totalResults,
                householdStats: action.householdBilling.stats
            };
        }
        case CHANGE_HOUSEHOLD_STATUS:
            return {
                ...state,
                householdStatus: action.status,
            };
        case CREATE_PAYOUT_BLOCK_INFO:
            return {
                ...state,
                payoutBlock: {
                    payoutBlockList: action.data.payoutBlock,
                    totalCreditTo: action.data.creditToTotal,
                    payoutPercentageTotal: action.data.payoutPercentageTotal
                },
            };
        case ADD_NEW_PAYOUT_BLOCK_INFO:
            return {
                ...state,
                payoutBlock: {
                    payoutBlockList: [
                        ...state.payoutBlock.payoutBlockList,
                        action.data,
                    ],
                    totalCreditTo: state.payoutBlock.totalCreditTo,
                    payoutPercentageTotal: state.payoutBlock.payoutPercentageTotal
                },
            };
        case DELETE_NEW_PAYOUT_BLOCK_INFO:
            return {
                ...state,
                payoutBlock: {
                    payoutBlockList: state.payoutBlock.payoutBlockList.filter(
                        (item) => item.payee !== ""
                    ),
                    totalCreditTo: state.payoutBlock.totalCreditTo,
                    payoutPercentageTotal: state.payoutBlock.payoutPercentageTotal
                },
            };
        case GET_EXCLUDED_ASSETS: {
            return {
                ...state,
                excludedAssets: action.data
            }
        }
        case LIVE_BILL_LIST: {
            return {
                ...state,
                liveBillList: action.data
            }
        }
        case RESET_PAY_BLOCK_LIST: {
            return {
                ...state,
                payoutBlock: {
                    ...state.payoutBlock,
                    payoutBlockList: null
                }
            }
        }
        case RESET_EXCLUDED_ASSETS: {
            return {
                ...state,
                excludedAssets: null
            }
        }
        case CLEAR_HOUSEHOLD_GENERAL_INFO: {
            return {
                ...state,
                generalInfo: {}
            }
        }
        case CLEAR_HOUSEHOLD_BILLING_INFO: {
            return {
                ...state,
                householdBilling: {},
                householdStatus: "ACTIVE",
            }
        }
        case CLEAR_HOUSEHOLD_BILLING_INFORMATION:{
            return{
                ...state,billingInformation:{}
            }
        }
        default:
            return state;
    }
}

var INIT_STATE = {
    selectedTabIndex: 0,
    selectedHouseholdId: null,
    isInitialRequestComplete: true,
    generalInfo: {
        managedAssets: 0,
        clientBilling: {},
        contact: {},
        other: {},
    },
    financialPlanning: {},
    billingInformation: {},
    tabs: [
        {
            id: "tab1",
            title: "General",
            key: "general",
            allow: true,
            grid: 1,
            active: true,
        },
        {
            id: "tab2",
            title: "Team",
            key: "teams",
            allow: true,
            grid: 1,
        },
        {
            id: "tab3",
            title: "Financial Planning Fees",
            key: "financialPlanningFee",
            allow: true,
            grid: 1,
        },
        {
            id: "tab4",
            title: "Payout Blocks",
            key: "payoutBlocks",
            allow: true,
            grid: 1,
        },
        {
            id: "tab5",
            title: "Billing Information",
            key: "billingInfo",
            allow: true,
            grid: 1,
        },
        {
            id: "tab6",
            title: "Investment Accounts",
            key: "investmentAccounts",
            allow: true,
            grid: 1,
        },
        {
            id: "tab7",
            title: "Exclude Assets",
            key: "excludeAssets",
            allow: true,
            grid: 1,
        },
        {
            id: "tab8",
            title: "Unit Live Bill",
            key: "unitLiveBill",
            allow: true,
            grid: 1,
        },
        {
            id: "tab9",
            title: "Logs",
            key: "logs",
            allow: true,
            grid: 1,
        },
        {
            id: "tab10",
            title: "Account Holdings",
            key: "holdings",
            allow: true,
            grid: 1,
        },
    ],
    householdBilling: {},
    totalResults: 0,
    householdStatus: "ACTIVE",
    payoutBlock: {
        payoutBlockList: null,
        totalCreditTo: 0,
        payoutPercentageTotal: 0,

    },
    excludedAssets: null,
    liveBillList: []
};

export default reducer;
